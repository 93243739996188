html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: "Roboto", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content--wrapper {
  min-height: 100vh;
  position: relative;
}

.page {
  width: 100vw;
  padding-top: 52px;
  text-align: center;
  min-height: calc(100% - 106px);
}


.search-bar {
  background-color: #333;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  padding: 10px;
}


.search-bar > * {
  width: 100%;
  max-width: 600px;
}


.top-bar {
  position: fixed;
  top: 0px;
  height: 50px;
  width: 100vw;
  background-color: #333;
  border-bottom: 10px solid #333;
  z-index: 100;
}

.top-bar-heading {
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  color: #FFE600;
  text-decoration: none;
  display: inline-block;
}



.flyoutMenu {
  width: 100vw;
  height: calc(100vh - 54px);
  background-color: #333;
  position: fixed;
  top: 53px;
  left: 0;
  transition: transform .3s
  cubic-bezier(0, .50, 0, 1);
  overflow-y: auto;
  z-index: 100;
  justify-content: center;
  text-align: center;
  padding-bottom: 10px;
}

.flyoutMenu.hide {
  transform: translate3d(-100vw, 0, 0);
}

.flyoutMenu.show {
  transform: translate3d(0vw, 0, 0);
}

.menu-content {
  padding-top: 58px;
  padding-bottom: 60px;
  max-width: 300px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.menu-song-list {
  margin-top: 30px;
}

.menu-content a {
  display: block;
  text-align: left;
  color: #FFE600;
  font-weight: bold;
  text-decoration: none;
}

.menu-category {
  font-size: 1.8rem;
  margin-top: 10px;
}

.menu-category-special {
  margin: 0;
}

.menu-song {
  margin-bottom: 10px;
  color: black;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2em;
}



.menu-button {
    position: absolute;
    right: 20px;
    top: 5px;
    cursor: pointer;
    display: inline-block;
    z-index: 200;
}

.menu-button:hover {
    cursor: pointer;
    transform: scale(1.1, 1.1);
}

.bar1, .bar2, .bar3 {
    width: 30px;
    height: 5px;
    background-color: white;
    margin: 6px 0;
    transition: 0.4s;
}

/* Rotate first bar */
.menu .bar1 {
    -webkit-transform: rotate(-45deg) translate(8px, 8px) ;
    transform: rotate(-45deg) translate(-8px, 8px) ;
}

/* Fade out the second bar */
.menu .bar2 {
    opacity: 0;
}

/* Rotate last bar */
.menu .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
    transform: rotate(45deg) translate(-8px, -8px) ;
}



.landing-page {
  height: calc(100vh - 52px);
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color:white;
}

.landing-image-container {
  position: relative;
}

.landing-image {
  flex: auto;
  height: 85vh;
  width: 100vw;
}

.self-promotion-link {
  transform: translate(-50%);
  position: absolute;
  top: 78vh;
  left: 25%;
}

.self-promotion-link svg {
  fill: #FFE600;
  height: 5vh;
}


.song-page {
  padding-bottom: 60px;
}

.song-header {
  font-size: 1.5em;
  padding-top: 83px;
  margin: 0;
  font-weight: bold;
}

.song-melody {
  font-style: italic;
  margin-top: 0;
  margin-bottom: 30px;
}

.song-lyrics {
  white-space: pre-wrap;
}

.song-footer {
  position: absolute;
  background-color: #333;
  padding: 15px 0px;
  width: 100%;
  bottom: 0;
  text-align: center;
}

.song-footer-category {
  color: #FFE600;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2em;
  margin: 0;
}


.category-header {
  font-size: 2em;
  padding-top: 83px;
  margin: 0;
  font-weight: bold;
}

.category-page {
  background-color: #333;
}

.category-songs {
  display: flex;
  flex-direction: column;
}

.category-image-container {
  height: 30vh;
  margin-bottom: 30px;
  margin-top: 30px;
}

.hymni {
  background-color: black;
  border-color: black;
}

.hymni-header {
  padding-top: 0;
}


.rules {
  width: 80vw;
  max-width: 830px;
  display: inline-block;
  text-align: left;
  line-height: 2rem;
  font-size: 1.5rem;
}

.indent {
  padding-left: 25px;
}
